import { reactive } from 'vue'
import CryptoJS from 'crypto-js'
// import crypto from 'crypto';
import router from './router/index'
// import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';
import {useLoading} from 'vue-loading-overlay'

export const globalState = reactive({
  loggedIn: false,
  licences: null,
  expired: true,
  user: null
})


const $loading = useLoading({

  // options
});


export const uuid = () => uuidv4()

export const wp = 'https://www.rhythmstix.co.uk/wp-json/jwt-auth/v1/token'
export  const APIURL = "https://asdev.rhythmstix.co.uk/api/v1";
export  const APIURL2 = "https://asdev.rhythmstix.co.uk/api/v2";
export const credit = 'https://www.rhythmstix.co.uk/redeemcredit.php'
//export  const APIURL = "http://localhost:8001/api/v1";
// export const APIURL = 'http://localhost:4000/api/v1'
// export const APIURL2 = 'http://localhost:4000/api/v2'

export const goto = (to) => router.push(to)
export const goback = () => router.go(-1)

const secretKey = 'C3C1QovZ9TmH3PqLwTxvoBbYSJa'

export const encrypt = (text) => {
  try {
    if (text === null) return null
    text = String(text)
    const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString()
    return encodeURIComponent(ciphertext) // URL encode the ciphertext
  } catch (error) {
    console.error('Error encrypting text:', error)
    return null
  }
}

export const decrypt = (ciphertext) => {
  try {
    if (ciphertext === null) return null
    const decodedCiphertext = decodeURIComponent(ciphertext) // URL decode the ciphertext
    const bytes = CryptoJS.AES.decrypt(decodedCiphertext, secretKey)
    return bytes.toString(CryptoJS.enc.Utf8)
  } catch (error) {
    console.error('Error decrypting ciphertext:', error)
    return null
  }
}
export const encode = (str) => btoa(str)
export const decode = (str) => atob(str)

const apilist = {
  get_posts: {
    method: 'GET',
    url: '/records/posts',
    auth: true
  },
  post_post: {
    method: 'POST',
    url: '/records/posts',
    auth: true
  },
  update_post: {
    method: 'PUT',
    url: '/records/posts',
    auth: true
  },
  delete_post: {
    method: 'DELETE',
    url: '/records/posts',
    auth: true
  },
  get_students: {
    method: 'GET',
    url: '/records/students',
    auth: true
  },
  post_student: {
    method: 'POST',
    url: '/records/students',
    auth: true
  },
  update_student: {
    method: 'PUT',
    url: '/records/students',
    auth: true
  },
  delete_student: {
    method: 'DELETE',
    url: '/records/students',
    auth: true
  },
  get_rubrics: {
    method: 'GET',
    url: '/records/rubrics',
    auth: true
  },
  post_rubric: {
    method: 'POST',
    url: '/records/rubrics',
    auth: true
  },
  update_rubric: {
    method: 'PUT',
    url: '/records/rubrics',
    auth: true
  },
  delete_rubric: {
    method: 'DELETE',
    url: '/records/rubrics',
    auth: true
  },
  get_assessments: {
    method: 'GET',
    url: '/records/assessments',
    auth: true
  },
  post_assessment: {
    method: 'POST',
    url: '/records/assessments',
    auth: true
  },
  update_assessment: {
    method: 'PUT',
    url: '/records/assessments',
    auth: true
  },
  delete_assessment: {
    method: 'DELETE',
    url: '/records/assessments',
    auth: true
  },
  get_prompts: {
    method: 'GET',
    url: '/records/prompts',
    auth: true
  },
  post_prompt: {
    method: 'POST',
    url: '/records/prompts',
    auth: true
  },
  update_prompt: {
    method: 'PUT',
    url: '/records/prompts',
    auth: true
  },
  delete_prompt: {
    method: 'DELETE',
    url: '/records/prompts',
    auth: true
  },
  generate:{
    method: 'POST',
    url: '/generate',
    auth: true
  },
  get_settings: {
    method: 'GET',
    url: '/records/settings',
    auth: true
  },
  update_settings: {
    method: 'PUT',
    url: '/records/settings',
    auth: true
  }
}

export const checkExpired = () => {
  const licences = globalState.licences

  // If licences is not defined or not an array, return true
  // if (!Array.isArray(licences) || licences.length === 0) {
  //   return true
  // }

  // Get the latest expiration date
  // const latestExpireDate = licences.reduce((max, current) => {
  //   const maxDate = new Date(max?.expire_date)
  //   const currentDate = new Date(current?.expire_date)
  //   return currentDate > maxDate ? current : max
  // }).expire_date

  // Check if the latest expiration date is in the past
  if(globalState.user?.data?.id == 1) return false
  if(globalState.user?.data?.id == 611) return false
  if(!licences) return true
  return new Date(licences) < new Date()
}

export const api = async (key, options = {}, v = 1) => {
  const { method = 'GET', auth = false, url } = apilist[key]
  const { route = '', body, params } = options
  const headers = { 'Content-Type': 'application/json', ...(auth ? { 'X-API-KEY': `d3NYMQDKCKBQFoZqpxiM` } : {}) }
  let theurl = `${ v == 1 ? APIURL : APIURL2 }${url}${route}${params ? '?' + new URLSearchParams(params).toString() : ''}`
  theurl += theurl.includes('?') ? `&timestamp=${new Date().getTime()}` : `?timestamp=${new Date().getTime()}`
  const response = await fetch(theurl, { method, headers, body: method !== 'GET' && body ? JSON.stringify(body) : undefined })
  const result = await response.json()
  if (result.code === 401) {
    logout()
    location.reload()
    return
  }
  return result
}

const resource_url = 'https://resources.rhythmstix.co.uk/'

export const login = async (data) => {
  const result = await fetch(wp, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((res) => res)
  if (result.statusCode == 200) {
    const licences = await getLicences(result?.data?.id)
    globalState.loggedIn = true
    globalState.licences = licences
    globalState.user = result
    localStorage.setItem('user', encrypt(JSON.stringify(result)))
    const expired = checkExpired()
    globalState.expired = expired
  }
  return result
}

export const getLicences = async (owner_id) => {
  const timestamp = new Date().getTime()
  const res = await fetch('https://asdev.rhythmstix.co.uk/api/v2/licences?owner_id='+owner_id + '&timestamp=' + timestamp).then(res => res.json())
  if (!res) return null
  return res.expire_at
  // const response = await fetch(resource_url + 'mylicences/assessify', {
  //   method: 'POST',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     Authorization: `bearer ${token}`
  //   }
  // })
  // const data = await response.json()
  // if (data.data !== 'NOT FOUND!') {
  //   return data.data
  // } else {
  //   return []
  // }
}
export const checkLogin = async (force = false) => {
  if (globalState.loggedIn && !force) {
    return true
  }
  const user = localStorage.getItem('user')
  if (user) {
    try {
      globalState.loggedIn = true
      globalState.user = JSON.parse(decrypt(user))
      const licences = await getLicences(globalState.user?.data?.id)
      globalState.licences = licences
      const expired = checkExpired()
      globalState.expired = expired
      return true
    } catch (error) {
      logout()
    }
  }
  return false
}
export const logout = () => {
  globalState.loggedIn = false
  globalState.user = null
  localStorage.clear()
}
export const getStudents = async (filters) => {
  const result = await api('get_students', { route: filters })
  return result
}
export const getStudent = async (id) => {
  const result = await api('get_students', { route: `${id}` })
  return result
}
export const postStudent = async (data) => {
  const result = await api('post_student', { body: data })
  return result
}
export const updateStudent = async (id, data) => {
  const result = await api('update_student', { route: `${id}`, body: data })
  return result
}
export const deleteStudent = async (id) => {
  const result = await api('delete_student', { route: `${id}` })
  return result
}

export const getRubrics = async (filters) => {
  const result = await api('get_rubrics', { route: filters })
  return result
}
export const getRubric = async (id) => {
  const result = await api('get_rubrics', { route: `${id}` })
  return result
}
export const postRubric = async (data) => {
  const result = await api('post_rubric', { body: data })
  return result
}
export const updateRubric = async (id, data) => {
  const result = await api('update_rubric', { route: `${id}`, body: data })
  return result
}
export const deleteRubric = async (id) => {
  const result = await api('delete_rubric', { route: `${id}` })
  return result
}

export const getAssessments = async (filters) => {
  const result = await api('get_assessments', { route: filters })
  return result
}
export const getAssessment = async (id) => {
  const result = await api('get_assessments', { route: `${id}` })
  return result
}
export const postAssessment = async (data) => {
  const result = await api('post_assessment', { body: data })
  return result
}
export const updateAssessment = async (id, data) => {
  const result = await api('update_assessment', { route: `${id}`, body: data })
  return result
}
export const deleteAssessment = async (id) => {
  const result = await api('delete_assessment', { route: `${id}` })
  return result
}

export const getprompts = async (filters) => {
  const result = await api('get_prompts', { route: filters })
  return result
}
export const getprompt = async (id) => {
  const result = await api('get_prompts', { route: `${id}` })
  return result
}
export const postprompt = async (data) => {
  const result = await api('post_prompt', { body: data })
  return result
}
export const updateprompt = async (id, data) => {
  const result = await api('update_prompt', { route: `${id}`, body: data })
  return result
}
export const deleteprompt = async (id) => {
  const result = await api('delete_prompt', { route: `${id}` })
  return result
}

export const getposts = async (filters) => {
  const result = await api('get_posts', { route: filters })
  return result
}
export const getpost = async (id) => {
  const result = await api('get_posts', { route: `${id}` })
  return result
}
export const postpost = async (data) => {
  const result = await api('post_post', { body: data })
  return result
}
export const updatepost = async (id, data) => {
  const result = await api('update_post', { route: `${id}`, body: data })
  return result
}
export const deletepost = async (id) => {
  const result = await api('delete_post', { route: `${id}` })
  return result
}

export const generate = async (data) => {
  const result = await api('generate', { body: data } , 2)
  return result
}

export const redeemCredit = async () => {
  const result = await fetch(credit+'?action=redeem&code='+encrypt(globalState.user.data.id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())

    console.log(result)
}

export const findPathById = (items, currentItemId) => {
  
  function findPathById(id) {
    return items?.find((item) => item.id === id)
  }

  function buildPath(itemId) {
    const item = findPathById(itemId)
    if (!item) return []

    const parentPath = item.post_parent ? buildPath(item.post_parent) : []
    return [...parentPath, item]
  }

  const path = buildPath(currentItemId)

  return path
}

let loader = null
let isloading = false
export const loading = (show = true) => {
  if (show && !isloading) {
    loader = $loading.show({});
    isloading = true
  }
  if(loader && isloading && !show) {
    loader?.hide()
    loader = null
    isloading = false
  }
}
