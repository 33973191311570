import { createRouter, createWebHistory } from 'vue-router'
import {checkLogin} from "/src/service.js"
// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/default/SignIn.vue')
  },
]

// Default routes
const defaultChildRoutes = (prefix) => [
  
  {
    path: '',
    name: prefix + '.assessments-list1',
    meta: { auth: true, name: 'Karyawan', isBanner: false },
    component: () => import('@/views/assessify/assessments/ListPage.vue')
  },
  {
    path: '/assessments',
    name: prefix + '.assessments-list',
    meta: { auth: true, name: 'Karyawan', isBanner: false },
    component: () => import('@/views/assessify/assessments/ListPage.vue')
  },
  {
    path: '/assessments/add',
    name: prefix + '.assessments-add',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/assessments/AddPage.vue')
  },
  {
    path: '/assessments/:id/edit',
    name: prefix + '.assessments-edit',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/assessments/AddPage.vue')
  },

  {
    path: '/criterias',
    name: prefix + '.criterias-list',
    meta: { auth: true, name: 'Karyawan', isBanner: false },
    component: () => import('@/views/assessify/rubrics/ListPage.vue')
  },
  {
    path: '/criterias/add',
    name: prefix + '.criterias-add',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/rubrics/AddPage.vue')
  },
  {
    path: '/criterias/import',
    name: prefix + '.criterias-import',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/rubrics/ImportPage.vue')
  },
  {
    path: '/criterias/:id/edit',
    name: prefix + '.criterias-edit',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/rubrics/AddPage.vue')
  },
  {
    path: '/library',
    name: prefix + '.library-list',
    meta: { auth: true, name: 'Karyawan', isBanner: false },
    component: () => import('@/views/assessify/library/ListPage.vue')
  },
  {
    path: '/library/add',
    name: prefix + '.library-add',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/library/AddPage.vue')
  },
  {
    path: '/library/:id/edit',
    name: prefix + '.library-edit',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/library/AddPage.vue')
  },

  {
    path: '/library/import',
    name: prefix + '.library-import',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/library/ImportPage.vue')
  },

  {
    path: '/classes',
    name: prefix + '.classes-list',
    meta: { auth: true, name: 'Karyawan', isBanner: false },
    component: () => import('@/views/assessify/students/ListPage.vue')
  },
  {
    path: '/classes/add',
    name: prefix + '.classes-add',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/students/AddPage.vue')
  },
  {
    path: '/classes/:id/edit',
    name: prefix + '.classes-edit',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/students/AddPage.vue')
  },
  {
    path: '/prompts',
    name: prefix + '.prompts-list',
    meta: { auth: true, name: 'Karyawan', isBanner: false },
    component: () => import('@/views/assessify/prompts/ListPage.vue')
  },
  {
    path: '/prompts/add',
    name: prefix + '.prompts-add',
    meta: { auth: true, name: 'Karyawan Add', isBanner: false },
    component: () => import('@/views/assessify/prompts/AddPage.vue')
  },
  {
    path: '/prompts/edit/:id',
    name: prefix + '.prompts-edit',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/prompts/AddPage.vue')
  },
  {
    path: '/licence',
    name: prefix + '.licence',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/licences/AddPage.vue')
  },
  {
    path: '/settings',
    name: prefix + '.settings',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/settings/AddPage.vue')
  },
  {
    path: '/backup',
    name: prefix + '.backup',
    meta: { auth: true, name: 'Karyawan Edit', isBanner: false },
    component: () => import('@/views/assessify/backup/AddPage.vue')
  },
  
]

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default')
  },
  {
    path: '/printxl/:id',
    name: 'printxl',
    component: () => import('@/views/assessify/assessments/PrintXl.vue')
  },
  // Auth Skins
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let logged = await checkLogin();

  if (to.matched.some((record) => record.meta.auth)) {
      if (!logged) {
          next({ name: 'auth.login' });
      } else {
        next();
      }
  } else {
      next();
  }
});
export default router
